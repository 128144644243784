import axios from 'axios'
const appConfig = require("./config");

export const callAPI = (apiMethodType, apiRoute, queryParamas, bodyData) => {
    let body = JSON.stringify(bodyData);

    const headers = Object.assign({ 'Content-Type': 'application/json' });
    let APIUrl = appConfig.BASE_URL + apiRoute;

    if(apiMethodType == "GET"){
        APIUrl = APIUrl + queryParamas;
    }

    const request = {
        method: apiMethodType,
        url: APIUrl,
        headers: headers,
        data : body
    };
    return axios(request).then(function (response) {
        return response.data;
    }).catch(function (error) {
        return {success : false, message : "There is some Issue. Please Conatact admin for Support."};
    });
}